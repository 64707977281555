import logo from "./images/logo.png"
import './App.css';

function App() {
  return (
    <div className="App">
      
      <img src={logo}  alt="logo"></img>
      <h1>Website Under Maintenance</h1>
    </div>
  );
}

export default App;
